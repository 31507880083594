import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Container } from '@aviationexam/core';
import { SEO } from 'components';
import { Locale, useAlternateLinks, usePublicTranslation } from 'features/localization';
import { BlogCategoryNode, BlogPostNode } from 'types/blog';
import { API_BLOG_PAGE } from 'config/routes';
import { CategoryNavigation, PostList } from './components';

interface DataType {
  allApiBlogPost: { nodes: BlogPostNode[] };
  allApiBlogCategory: { nodes: BlogCategoryNode[] };
}

interface Context {
  locale: Locale;
  pagination: {
    next: string;
    previous: string;
  };
}

export default function Posts({
  data: {
    allApiBlogPost: { nodes: posts },
    allApiBlogCategory: { nodes: categories },
  },
  pageContext: { locale, pagination },
}: PageProps<DataType, Context>) {
  const { t } = usePublicTranslation();
  const { alternateLinks } = useAlternateLinks(API_BLOG_PAGE, locale);

  return (
    <>
      <SEO title={t('blogPage.title')} description={t('site.description')} lang={locale}>
        {alternateLinks}
      </SEO>
      <CategoryNavigation categories={categories} />
      <Container size="xl">
        <PostList
          title={t('blogPage.heading')}
          posts={posts}
          nextPage={pagination.next}
          previousPage={pagination.previous}
        />
      </Container>
    </>
  );
}

export const pageQuery = graphql`
  query BlogPostsPosts($blogPostIds: [String!], $locale: String!) {
    allApiBlogCategory(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        route
      }
    }
    allApiBlogPost(
      filter: { id: { in: $blogPostIds } }
      sort: { fields: createdDate, order: DESC }
    ) {
      nodes {
        id
        title
        author
        createdDate
        locale
        route
        description
        alternateUrls {
          locale
          route
        }
        featuredImg {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: BLURRED
              formats: [WEBP, AUTO]
            )
          }
        }
      }
    }
  }
`;
